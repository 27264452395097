export const mentors = [
  {
    id: "nguyen-xuan-phong",
    name: "Nguyễn Xuân Phong",
    position: "Cloud Solution Architect",
    localtion: "Tokyo, Japan",
    thumbnail: "xuan-phong/webp/thumbnail.webp",
    avatar: "xuan-phong/webp/avatar.webp",
    active_certifications: [
      `AWS Certified AI Practitioner (AIF)`,
      `AWS Certified Solutions Architect - Associate (SAA)`,
      `AWS Certified Developer - Associate (DVA)`,
      `AWS Certified SysOps Administrator - Associate (SOA)`,
      `AWS Certified Solutions Architect - Professional (SAP)`,
      `AWS Certified DevOps Engineer - Professional (DOP)`,
      `AWS Certified Security - Specialty (SCS)`,
      `AWS Certified Database - Specialty (DBS)`,
    ],
    certificates: [
      {
        image: "xuan-phong/webp/AIF.webp",
      },
      {
        image: "xuan-phong/webp/SAA.webp",
      },
      {
        image: "xuan-phong/webp/DVA.webp",
      },
      {
        image: "xuan-phong/webp/SOA.webp",
      },
      {
        image: "xuan-phong/webp/SAP.webp",
      },
      {
        image: "xuan-phong/webp/DOP.webp",
      },
      {
        image: "xuan-phong/webp/SCS.webp",
      },
      {
        image: "xuan-phong/webp/DBS.webp",
      },
    ],
    about: `Với gần 10 năm kinh nghiệm làm việc cho các khách hàng lớn của
    Nhật, trải qua nhiều vị trí khác nhau: Web Application
    Developer, DevOps Engineer, Bridge System Engineer, Cloud
    Engineer.`,
    experiences: [
      `Có kinh nghiệm thiết kế, xây dựng, vận hành hệ thống trên
      AWS, đặc biệt là Cloud Native.`,
      `Có kinh nghiệm luyện thi nhiều khóa AWS nội bộ và bên ngoài
      đạt tỷ lệ pass 90% lần đầu.`,
      `Mentor có đam mê và nhiệt huyết với công việc giảng dạy`,
    ],
  },
  {
    id: "bui-tuan-minh",
    name: "Bùi Tuấn Minh",
    position: "Cloud Engineer",
    localtion: "Tokyo, Japan",
    thumbnail: "tuan-minh/webp/thumbnail.webp",
    avatar: "tuan-minh/webp/avatar.webp",
    active_certifications: [
      `AWS Certified Solutions Architect - Associate (SAA)`,
      `AWS Certified Developer - Associate (DVA)`,
      `AWS Certified SysOps Administrator - Associate (SOA)`,
      `AWS Certified Data Engineer - Associate (DEA)`,
      `AWS Certified Solutions Architect - Professional (SAP)`,
      `AWS Certified DevOps Engineer - Professional (DOP)`,
      `AWS Certified Data Analytics - Specialty (DAS)`,
      `AWS Certified Security - Specialty (SCS)`,
      `AWS Certified Database - Specialty (DBS)`,
      `AWS Certified Advanced Networking - Specialty (ANS)`,
    ],
    certificates: [
      {
        image: "tuan-minh/webp/SAA.webp",
      },
      {
        image: "tuan-minh/webp/DVA.webp",
      },
      {
        image: "tuan-minh/webp/SOA.webp",
      },
      {
        image: "tuan-minh/webp/DEA.webp",
      },
      {
        image: "tuan-minh/webp/SAP.webp",
      },
      {
        image: "tuan-minh/webp/DOP.webp",
      },
      {
        image: "tuan-minh/webp/DAS.webp",
      },
      {
        image: "tuan-minh/webp/SCS.webp",
      },
      {
        image: "tuan-minh/webp/DBS.webp",
      },
      {
        image: "tuan-minh/webp/ANS.webp",
      },
    ],
    about:
      "Với hơn 3 năm kinh nghiệm làm việc cho khách hàng lớn của Nhật, trải qua các công việc như: Thiết kế, làm PoC thử nghiệm kiến trúc, xây dựng và kiểm thử hệ thống. Hiện tại đang làm việc như 1 member tại công ty Khách hàng là công ty chuyên tư vấn giải pháp về AWS Cloud.",
    experiences: [
      `Có kinh nghiệm thực tế nhiều dự án với AWS, đặc biệt là serverless technology`,
      `Kinh nghiệm training nhiều khóa AWS nội bộ và bên ngoài`,
      `Nhiệt tình hỗ trợ học viên trong và ngoài lớp học`,
    ],
  },
  {
    id: "tran-trung-tin",
    name: "Trần Trung Tín",
    position: "Cloud Engineer",
    localtion: "Tokyo, Japan",
    thumbnail: "trung-tin/webp/thumbnail.webp",
    avatar: "trung-tin/webp/avatar.webp",
    active_certifications: [
      `AWS Certified Solutions Architect - Associate (SAA)`,
      `AWS Certified Developer - Associate (DVA)`,
      `AWS Certified Solutions Architect - Professional (SAP)`,
    ],
    certificates: [
      {
        image: "trung-tin/webp/SAA.webp",
      },
      {
        image: "trung-tin/webp/DVA.webp",
      },
      {
        image: "trung-tin/webp/SAP.webp",
      },
    ],
    about: `Hiện tại đang làm việc với khách hàng Nhật là các tập đoàn lớn và các end-users.
    Kinh qua các công việc: hỗ trợ thiết kế, phát triển infra cho những systems chạy trên cloud (AWS), 
    kiểm thử solution trên aws, aws support, quản lý dự án, làm báo giá, hearing requirement từ khách hàng để làm tài liệu thiết kế, training nội bộ.
    `,
    experiences: [
      `Services yêu thích: AWS CDK, Lambda`,
      `Có kinh nghiệm luyện thi chứng chỉ AWS`,
      `Có đam mê và nhiệt huyết với công việc giảng dạy`,
    ],
  },
  {
    id: "huynh-nhat-minh",
    name: "Huỳnh Nhật Minh",
    position: "Senior Software Engineer",
    localtion: "Tokyo, Japan",
    thumbnail: "nhat-minh/webp/thumbnail.webp",
    avatar: "nhat-minh/webp/avatar.webp",
    active_certifications: [
      'AWS Certified Solutions Architect - Associate (SAA)',
      'AWS Certified Developer - Associate (DVA)',
      'AWS Certified SysOps Administrator - Associate (SOA)',
      'AWS Certified Solutions Architect - Professional (SAP)',
      'AWS Certified DevOps Engineer - Professional (DOP)',
      'AWS Certified Security - Specialty (SCS)',
      'AWS Certified Advanced Networking - Specialty (ANS)',
      'AWS Certified Machine Learning - Specialty (MLS)',
      'Google Cloud Certified - Professional Machine Learning',
      'Google Cloud Certified - Professional Cloud Architect',
      'Google Cloud Certified - Professional Cloud DevOps Engineer',
      'Google Cloud Certified - Professional Cloud Security Engineer',
      'Google Cloud Certified - Professional Cloud Database Engineer',
      'Google Cloud Certified - Professional Cloud Network Engineer',
      `NVIDIA-Certified Associate: Multimodal Generative AI`,
      `NVIDIA-Certified Associate: Generative AI and LLMs`,
      `Databricks Certified Machine Learning Professional`,
      'Databricks Certified Data Engineer Professional',
      'Databricks Certified Data Analyst Associate',
      'Databricks Certified Data Engineer Associate',
      'Databricks Certified Machine Learning Associate',
    ],
    certificates: [
      {
        image: "nhat-minh/webp/SAA.webp",
      },
      {
        image: "nhat-minh/webp/DVA.webp",
      },
      {
        image: "nhat-minh/webp/SOA.webp",
      },
      {
        image: "nhat-minh/webp/SAP.webp",
      },
      {
        image: "nhat-minh/webp/DOP.webp",
      },
      {
        image: "nhat-minh/webp/SCS.webp",
      },
      {
        image: "nhat-minh/webp/ANS.webp",
      },
      {
        image: "nhat-minh/webp/MLS.webp",
      },
      {
        image: "nhat-minh/webp/G-PMLE.webp",
      },
      {
        image: "nhat-minh/webp/G-PCA.webp",
      },
      {
        image: "nhat-minh/webp/G-P-CloudDevOps-Engineer.webp",
      },
      {
        image: "nhat-minh/webp/G-PCSE.webp",
      },
      {
        image: "nhat-minh/webp/G-PCDE.webp",
      },
      {
        image: "nhat-minh/webp/G-P-CloudNetwork-Engineer.webp",
      },
    ],
    about: `Hơn 5+ năm kinh nghiệm làm việc trong lĩnh vực Software Development và Cloud Computing, 
    đã làm việc tại nhiều vị trí: Backend Developer, MLOps Engineer, Cloud DevOps Engineer.
    Hiện tại đang làm việc tại đơn vị Cloud/Data Solutions Department của FPT Japan.`,
    experiences: [
      `Kinh nghiệm thiết kế, triển khai, tư vấn nhiều giải pháp Cloud/AI trên Cloud.`,
      `Kinh nghiệm CI/CD cho Web/Application/ML Model, triển khai các Frontier-Models, Generative AI trên Cloud.`,
      `Nhiệt tình hỗ trợ học viên trong và ngoài lớp.`,
    ],
  },
];
