<template>
  <v-container>
    <v-row class="mb-10">
      <v-col cols="12">
        <div class="course-name mt-6">
          Luyện thi<br /><span class="orange--text"
            >AWS Certified Machine Learning Engineer - Associate (MLA-C01)</span
          >
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <v-layout justify-center>
          <v-img
            :src="
              require('@/assets/webp/AWS-Certified-Machine-Learning-Engineer-Associate_badge.webp')
            "
            max-width="280px"
            alt="mla"
            lazy
          ></v-img>
        </v-layout>
      </v-col>
      <v-col cols="12" md="8">
        <div class="course-info-block-title mb-6 text-center text-md-left">
          Thông tin khóa học
        </div>
        <div class="course-detail-info mb-6">
          <v-row
            class="mb-3"
            no-gutters
            v-for="(detail, key) in details"
            :key="key"
          >
            <v-col cols="12" sm="4" md="3" class="pr-sm-5">
              <div class="text-center text-sm-right">
                {{ detail.label }}
              </div>
            </v-col>
            <v-col cols="12" sm="8" md="9">
              <div class="text-center text-sm-left">{{ detail.value }}</div>
            </v-col>
          </v-row>
        </div>
        <div class="text-center text-md-left">
          <a
            class="btn-scale btnRegisterCourse"
            href="https://forms.gle/9kC3wdcbU3zRPmVNA"
            target="_blank"
            aria-label="Đăng ký ngay"
            >Đăng ký ngay</a
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="course-info-block-title">Giới thiệu chứng chỉ</div>
        <div class="course-info-block-content">
          <p>
            <b>AWS Certified Machine Learning Engineer - Associate</b> là một chứng chỉ do Amazon Web Services (AWS) cung cấp 
            , dành cho những người có kiến thức cơ bản về xây dựng, triển khai và duy trì các giải pháp máy học (Machine Learning) trên nền tảng AWS. 
            Để đạt được chứng chỉ này, người học cần nắm vững các khía cạnh khác nhau của quy trình phát triển mô hình ML, 
            từ việc thu thập dữ liệu, làm sạch dữ liệu, đào tạo và tối ưu hóa mô hình đến việc triển khai và giám sát mô hình trong môi trường production.
          </p>

          <p>
            Chứng chỉ này kiểm tra kỹ năng của người học thông qua các khía cạnh như: 
            lựa chọn và triển khai các dịch vụ AWS thích hợp cho các nhiệm vụ Machine Learning, 
            tối ưu hóa hiệu suất của mô hình ML, và hiểu biết về các thuật toán và phương pháp phân tích dữ liệu. 
            Những dịch vụ liên quan đến ML trên AWS bao gồm AWS SageMaker, 
            AWS Bedrock for Generative AI,... và các công cụ phân tích dữ liệu khác.
          </p>

          <p>
            Chứng chỉ này phù hợp cho các Developer, Data Engineer, Solution Architecture, 
            Cloud Devops Engineer muốn chứng minh khả năng làm việc với AI/Machine Learning trong môi trường AWS.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="course-info-block-title">Mục tiêu khóa học</div>
        <div class="course-info-block-content">
          <ul>
            <li v-for="(goal, key) in goals" :key="key">{{ goal }}</li>
          </ul>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="course-info-block-title">Đối tượng tham gia khóa học</div>
        <div class="course-info-block-content">
          <ul>
            <li v-for="(student, key) in students" :key="key">{{ student }}</li>
          </ul>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="course-info-block-title">Yêu cầu đầu vào</div>
        <div class="course-info-block-content">
          <ul>
            <li v-for="(condition, key) in conditions" :key="key">
              {{ condition }}
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="course-info-block-title">Hình thức học</div>
        <div class="course-info-block-content">
          <ul>
            <li v-for="(format, key) in formats" :key="key">
              {{ format }}
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="course-info-block-title">Cam kết</div>
        <div class="course-info-block-content">
          <ul>
            <li v-for="(commit, key) in commits" :key="key">
              {{ commit }}
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="course-info-block-title">Ưu đãi</div>
        <div class="course-info-block-content">
          <ul>
            <li v-for="(offer, key) in offers" :key="key">
              {{ offer }}
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="course-info-block-title">Nội dung khóa học</div>
        <div class="course-info-block-content">
          <v-row style="flex-wrap: nowrap" no-gutters class="mb-3">
            <v-col
              cols="2"
              class="flex-grow-0 flex-shrink-0 hidden-xs-only mr-3"
            >
              <v-card
                class="pa-3 font-weight-bold text-center"
                flat
                tile
                color="#02244b"
                dark
              >
                Session
              </v-card>
            </v-col>
            <v-col
              cols="1"
              style="max-width: 100%"
              class="flex-grow-1 flex-shrink-0"
            >
              <v-card
                class="pa-3 font-weight-bold text-center"
                flat
                tile
                color="#02244b"
                dark
              >
                Content
              </v-card>
            </v-col>
          </v-row>
          <v-row
            style="flex-wrap: nowrap"
            class="mb-3"
            no-gutters
            v-for="(content, key) in contents"
            :key="key"
          >
            <v-col
              cols="2"
              class="flex-grow-0 flex-shrink-0 hidden-xs-only mr-3"
            >
              <v-card
                class="pa-3 main-text d-flex flex-column align-center justify-center"
                height="100%"
                grid-list-md
                fill-height
                flat
                tile
                color="#ffeee3"
              >
                {{ key + 1 }}
              </v-card>
            </v-col>
            <v-col
              cols="1"
              style="max-width: 100%"
              class="flex-grow-1 flex-shrink-0"
            >
              <v-card
                class="pa-3 main-text fill-height"
                flat
                tile
                color="#fff9f5"
              >
                <div class="hidden-sm-and-up font-weight-bold">
                  Session {{ key + 1 }}:
                </div>
                <div v-for="(text, key) in content" :key="key">{{ text }}</div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MlaView",

  data: () => ({
    details: [
      {
        label: "Lịch khai giảng",
        value: "17-02-2025 (Mon)",
      },
      {
        label: "Học phí",
        value: "4,500,000 vnđ",
      },
      {
        label: "Lịch học",
        value:
          "17 buổi học (14 buổi học lý thuyết + Thực hành, 3 buổi luyện đề)",
      },
      {
        label: "Thời gian học",
        value: "Thứ 2, 5 từ 19:30 - 21:30",
      },
      {
        label: "Hình thức học",
        value: "Online - tương tác qua Google Meet",
      },
    ],
    goals: [
      "Hiểu biết tổng quan về các service AI/ML trên hạ tầng AWS",
      "Khóa học được thiết kế tập trung về phần Hand-ons thực tế, triển khai các giải pháp trong Real-world, sẽ giúp bạn tự tin phỏng vấn, upskill về AI/ML on AWS",
      "Có thể tư vấn, triển khai các giải pháp liên quan đến AI/ML trên AWS cho khách hàng, công ty, doanh nghiệp",
      "Lựa chọn thuật toán Machine Learning: Hiểu và áp dụng đúng các thuật toán ML phổ biến, biết cách lựa chọn thuật toán phù hợp dựa trên bài toán thực tế.",
      "Hiểu biết về quy trình Machine Learning, MLOps trên Cloud",
      "Nắm vững kiến thức cơ bản đến nâng cao, biết cách triển khai, áp dụng trong các bài toán thực tế về Generative AI.",
      "Thiết kế kiến trúc, giải pháp ML trên AWS",
      "Được cung cấp kiến thức thực tế khi làm việc, các bài lab liên quan đến chứng chỉ giúp học viên vận dụng vào thực tế công việc.",
      "Được giải thích chi tiết bộ đề luyện thi và các Tips làm bài thi",
      "Tự tin tham gia kỳ thi AWS-Machine Learning Associate",
      "Nâng cao thu nhập, tăng cơ hội mở rộng việc làm",
    ],
    students: [
      "Developer có định hướng làm việc với AWS, có kiến trức nền tảng về AWS",
      "Data/Analyst Engineer, những người chịu trách nhiệm xây dựng và tối ưu các Model, cần nắm vững cách triển khai và quản lý mô hình trên môi trường AWS",
      "Sinh viên CNTT muốn tìm hiểu, bổ sung kiến thức và chứng nhận năng lực về AI/ML/Cloud",
      "BA/Solution muốn tìm hiểu về AI/ML để tư vấn giải pháp cho khách hàng",
      "Những bạn muốn đạt chứng chỉ Quốc tế, có skill về AI/ML để nâng cao thu nhập và cơ hội thăng tiến",
    ],
    conditions: [
      "Khả năng đọc hiểu tiếng Anh tương đối",
      "Đã lập trình một ngôn ngữ bất kỳ, hiểu cơ bản về SQL",
      "Có kiến thức cơ bản về AWS (Đã học qua hoặc có chứng chỉ SAA hoặc DVA)",
      "Đam mê tìm hiểu các công nghệ về AI/ML/GenAI",
    ],
    formats: [
      "Học ONLINE, tương tác trực tiếp với Mentor qua Google Meet",
      "Có video record gửi đến học viên sau mỗi buổi học",
      "Học viên và Mentor trao đổi ngoài buổi học thông qua Discord",
    ],
    commits: [
      "PASS chứng chỉ AWS Certified Machine Learning - Associate nếu học viên tham gia trên 80% buổi học",
      "Mentor hỗ trợ học viên liên tục trong và sau khóa học.",
      "Hỗ trợ học viên học lại miễn phí cho đến khi pass chứng chỉ",
      "Hoàn lại học phí nếu học viên thấy không phù hợp trong 3 buổi học đầu tiên",
    ],
    offers: [
      "Ưu đãi giảm 200,000 vnđ cho học viên đăng ký theo nhóm",
      "Ưu đãi giảm 300,000 vnđ cho học viên cũ",
      "2 suất học bổng mỗi khóa - mỗi suất 1 triệu đồng : dành cho 2 học viên thi đỗ chứng chỉ AWS sớm nhất, trong vòng 1 tháng kể từ sau khi khóa học kết thúc.",
    ],
    contents: [
      [
        "Course Introduction",
        "Overview of Data Ingestion and Storage",
        "1. Know the Differences: AI vs Machine Learning vs Deep Learning",
        "2. Types of data",
        "3. Data warehouse vs Data Lakes vs Data Lakehouse",
        "4. Cloud storage service on AWS",
        "5. Hands-on: Creating and Configuring an S3 Lifecycle Policy",
        "Quiz",
      ],
      [
        "ETL , Amazon EMR, Amazon Kinesis Service and Amazon MSK",
        "1. Extract, Transform, Load (ETL) with Amazon Glue",
        "2. Data Engineering with Amazon EMR",
        "3. Basic to Advantage with Amazon Kinesis Service",
        "4. Amazon Managed Streaming for Apache Kafka (Amazon MSK) ",
        "5. Hands-on: Data Pipeline - AWS Glue for ETL, Integrating Athena for Querying the Processed Data",
        "Quiz"
      ],
      [
        "AWS Managed AI Services",
        "1. Basic to Advance: 7 AWS AI Managed Services (Amazon Comprehend, Amazon Translate, Amazon Transcribe, Amazon Polly, Amazon Rekognition, Amazon Forecast, Amazon Lex + Connect )",
        "2. Hands-on: Using AWS Transcribe to Batch Transcribe Hundreds of Audio Files",
        "Quiz"
      ],
      [
        "AWS Managed AI Services",
        "1. Basic to Advance: 7 AWS AI Managed Services (Amazon Personalize, Amazon Textract, Amazon Kendra, Amazon Augemented AI, Amazon Lookout, Amazon Fraud Detector, Amazon Q Business/Developer )",
        "2. Hands-on: Securing Your Code With Amazon Q Developer",
        "Quiz",
      ],
      [
        "Deep dive with Amazon SageMaker and Classification of Machine Learning Algorithms",
        "1. Deep dive main feature on SageMaker Training & Deployment",
        "2. Classification of Machine Learning Algorithms, Amazon SageMaker's built-in machine learning algorithms (XGBoost, BlazingText, Object Detection, etc.) ",
        "3. Handling Unbalanced Data",
        "4. Hands-on: A Step-by-Step Guide for Training a Basic Model using SageMaker",
        "Quiz",
      ],
      [
        "Deep Learning, Large Language Model (LLM) and Top frontier LLM in 2024",
        "1. Foundational Deep Learning",
        "2. Large Language Model",
        "3. Advanced: LLM: Top 6 Frontier Model Most Powerful Large Language Models for 2024",
        "4. Lab: Using AWS Nova Multimodal Model to Generate Videos",
        "Quiz"
      ],
      [
        "Deep dive Generative AI in AWS - Amazon Bedrock",
        "1. AWS Foundation Models (Base Models)",
        "2. Retrieval Augmented Generation (RAG)",
        "3. Amazon Bedrock",
        "4. LLM Agents",
        "Lab / Quiz",
      ],
      [
        "Learn from real-world: Build solution Generative AI for the Advertising and Marketing Industry ( AppSync, DynamoDB, Lambda, AWS Bedrock, AWS Rekognition... )",
        "1. Scenario/UseCase real-world",
        "2. Introduction solution architecture",
        "3. Explain flow",
        "4. Implement solution in AWS follow the instructions.",
        "5. Share estimate cost for architecture",
        "Quiz",
      ],
      [
        "ML Implementation and Operations - Part 1", 
        "1. Containers on AWS",
        "2. AWS Service commonly used in MLOps",
        "3. Overview Git/Git flow",
        "Lab / Quiz"
      ],
      [
        "ML Implementation and Operations - Part 2", 
        "1. Overview MLOps & Principles of MLOps",
        "2. MLOps with Sagemaker and Docker containers",
        "3. Managing Sagemaker Resource",
        "4. Advanced: Well-Architecture Framework",
        "Lab / Quiz"
      ],
      [
        "Learn from real-world: Building intelligent image analysis solutions on Customize Business ( Rekognition Custom Labels, AWS S3, AWS Lambda, SNS...)",
        "1. Scenario/UseCase real-world",
        "2. Introduction solution architecture",
        "3. Explain flow",
        "4. Implement solution in AWS follow the instructions.",
        "5. Share estimate cost for architecture",
        "Quiz"
      ],
      [
        "Security, Identity, and Compliance",
        "1. Overview Securing data in SageMaker (AWS IAM, KMS, Macie, Secrets Manager, WAF, Shield, VPC and Subnets Primer)",
        "2. Advanced: Top 10 best practice security in Amazon SageMaker AI for production workloads",
        "3. Lab: Detecting PII in Amazon S3 with AWS Macie",
        "Quiz"
      ],
      [
        "Management and Governance",
        "1. Basic to Advand with AWS CloudWatch, AWS CloudTrail, AWS Config",
        "2. Overview AWS Budgets, AWS Cost Explorer ",
        "3. Overview Cost Optimization Hub",
        "4. Lab: Install CloudWatch agent using AWS Systems Manager",
        "Quiz"
      ],
      [
        "Learn from real-world: Use OpenSearch Service as a vector store for gen AI applications ( Amazon OpenSearch Service, Amazon SageMaker, Amazon Bedrock, Anthropic Claude...)",
        "1. Scenario/UseCase real-world",
        "2. Introduction solution architecture",
        "3. Explain flow",
        "4. Implement solution in AWS follow the instructions",
        "5. Share estimate cost for architecture",
        "Quiz"
      ],
      [
        "AWS Certification Paths - Machine Learning role",
        "Pratice Exam 1 (Explanations and Tips)"
      ],
      ["Pratice Exam 2 (Explanations and Tips)"],
      ["Pratice Exam 3 (Explanations and Tips)"],
    ],
  }),
};
</script>

<style lang="scss" scoped>
@import "@/scss/common";

.course-name {
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
  color: $blue-dark;
}

.course-detail-info {
  color: $grey-1;
  font-weight: 500;
}

.course-info-block-title {
  text-transform: uppercase;
  color: $blue-dark;
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.course-info-block-content {
  color: $grey-1;

  ul {
    li {
      margin-bottom: 8px;
    }
  }
}

.btnRegisterCourse {
  font-size: 1.1rem;
  font-weight: 500;
  color: $white;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  padding: 6px 30px;
  background: $orange;
  border-radius: 10px;
  border: 2px solid #fff;
  box-shadow: 0 0 0 1px rgba($color: $orange, $alpha: 0.2);
}
</style>
