import { mentors } from "./Mentor";

export const courses = [
  {
    name: "AWS Certified Solutions Architect - <br/>Associate (SAA-C03)",
    price: "3,480,000 VND",
    descriptions:
      "Cung cấp kiến thức và kỹ năng cần thiết như một Solution Architect trên AWS và đảm bảo giúp bạn pass chứng chỉ SAA-C03",
    link: "saa",
    image: "webp/course-box-image-SAA-25.webp",
    mentors: [...mentors.filter(mentor => 
      mentor.id === "nguyen-xuan-phong" || mentor.id === "bui-tuan-minh"
    )],
  },
  // {
  //   name: "AWS Certified Solutions Architect - <br/>Associate (SAA-C03)",
  //   price: "3,480,000 VND",
  //   descriptions:
  //     "Cung cấp kiến thức và kỹ năng cần thiết như một Solution Architect trên AWS và đảm bảo giúp bạn pass chứng chỉ SAA-C03",
  //   link: "saa37",
  //   image: "webp/course-box-image-SAA-37.webp",
  //   mentors: [...mentors],
  // },
  {
    name: "AWS Certified Developer - <br/>Associate (DVA-C02)",
    price: "3,480,000 VND",
    descriptions:
      "Cung cấp kiến thức và kỹ năng cần thiết cho Developer trên AWS và đảm bảo giúp bạn pass chứng chỉ DVA-C02",
    link: "da",
    image: "webp/course-box-image-DVA.webp",
    mentors: [...mentors.filter(mentor => 
      mentor.id === "nguyen-xuan-phong" || mentor.id === "tran-trung-tin"
    )],
  },
  {
    name: "AWS Certified Machine Learning <br/> Engineer - Associate",
    price: "4,500,000 VND",
    descriptions:
      "Cung cấp kiến thức và khả năng kỹ thuật trong việc triển khai ML workloads trong môi trường production. Không chỉ giúp bạn vượt qua kỳ thi Machine Learning Engineer - Associate mà còn cung cấp các giải pháp vận hành ML Real-World",
    link: "mla",
    image: "webp/course-box-image-MLA.webp",
    mentors: [...mentors.filter(mentor => 
      mentor.id === "huynh-nhat-minh"
  )],
  },
  {
    name: "AWS Certified Solutions Architect - <br/>Professional (SAP-C02)",
    price: "",
    descriptions:
      "Cung cấp kiến thức và kỹ năng nâng cao trong việc cung cấp các giải pháp phức tạp cho các vấn đề phức tạp, optimizing security, cost, and performance, and automating manual processes. Giúp bạn pass chứng chỉ SAP-C02",
    link: "sap",
    image: "webp/course-box-image-SAP.webp",
    mentors: [...mentors.filter(mentor => 
      mentor.id === "nguyen-xuan-phong" || mentor.id === "bui-tuan-minh"
    )],
  },
];
